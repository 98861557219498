/* eslint-disable react/no-unescaped-entities, max-len */

import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../containers/default'
import Wrap from '../components/layout/wrap'
import {
    DocumentTitle,
    BoldParagraph,
    Paragraph,
    H2,
    LegalEmailLink,
} from '../components/legal-formatting'

const LegalWrap = styled(Wrap)`
    padding: 5rem 0;
`

export default () => (
    <DefaultLayout>
        <Helmet>
            <title>Jawbone Health Privacy Policy</title>
            <meta name="description" content="Jawbone Health Privacy Policy" />
        </Helmet>
        <LegalWrap>
            <DocumentTitle>Jawbone Health Hub Privacy Policy</DocumentTitle>
            <BoldParagraph>Effective as of September 2018</BoldParagraph>
            <Paragraph>
                At Jawbone Health Hub we value you as a customer and respect
                your privacy. This Privacy Policy (“Privacy Policy”) describes
                our privacy practices. This is where we describe what kind of
                data we collect, how we use it, the controls we give you over
                your information, and how we keep it safe. Please read this
                privacy policy carefully to learn about your rights and choices
                regarding our processing of your personal data. Use of this
                website constitutes acknowledgement and, where applicable,
                consent to Jawbone Health Hub’s collection and use of your
                personal data as discussed below. Jawbone Health Hub is an
                company located in the United States. If you are located outside
                of the United States, we may use facilities outside of your home
                country to process, store, or back up electronic information.
            </Paragraph>
            <Paragraph>
                A reference to “Jawbone Health”, “we”, “us”, or the “Company” is
                a reference to Jawbone Health Hub, Inc.
            </Paragraph>
            <H2>1. Information we collect</H2>
            <Paragraph>
                When you visit our website, we collect information from you in a
                few different ways. Some information you chose to provide, such
                as if you chose to submit your email address to receive updates
                or to join our mailing list. And some information we
                automatically receive from your browser or mobile device, such
                as your IP address or unique device identifier, cookies and data
                regarding which pages you visit, and type of web browser used to
                visit our site. We also use Google Analytics to help collect and
                analyze certain information about you (such as age, gender, and
                interests).
            </Paragraph>
            <Paragraph>
                When we use the term “Personal Data” we mean information that
                can or could be used to identify you as an individual person.
            </Paragraph>
            <H2>2. How we use the information we collect</H2>
            <Paragraph>
                Jawbone Health may use the Personal Data that we collect for
                internal purposes, namely for analyzing usage statics, solving
                technical problems, for marketing and promotional purposes, for
                product development, and content improvement. We may also use
                Personal Data to communicate with you about our products and
                services, let you know about our policies and terms, and to
                respond to you when you contact us. Jawbone Health will not
                disclose Personal Data to third parties, except when necessary
                to comply with applicable laws or legal processes (such as
                subpoenas, court orders, regulatory requests, or
                investigations), to provide requested cooperation to law
                enforcement authorities, or to protect the rights and property
                of Jawbone Health.
            </Paragraph>
            <H2>3. How we protect the information we collect</H2>
            <Paragraph>
                The security of Personal Data is important to us. Jawbone Health
                implements physical and electronic security features to prevent
                the unauthorized release of or access to Personal Data. No
                method of transmitting or securing data is completely secure,
                however, and Jawbone Health does not guarantee the security of
                information transmitted over the Internet.
            </Paragraph>
            <H2>4. Information from children</H2>
            <Paragraph>
                Jawbone Health is concerned about the safety and security of
                children. Our website is intended for a general audience and not
                targeted to or intended for use by children under the age of 16.
                We do not solicit information from children under the age of 16
                and children should not submit any Personal Data or personal
                information to Jawbone Health or this website.
            </Paragraph>
            <H2>5. Your right to access and control your personal data</H2>
            <Paragraph>
                We want to communicate with you only if you want to hear from
                us. At any time you may choose to stop receiving commercial
                messages from us by submitting an opt-out request to the contact
                information below or by clicking “unsubscribe” in any marketing
                email you receive from us.
            </Paragraph>
            <Paragraph>
                You may review your Personal Data that is readily accessible
                through this website or request that it be updated, corrected,
                or removed by contacting us at the contact information below.
            </Paragraph>
            <H2>6. How to contact us</H2>
            <Paragraph>
                If you would like to contact us about this Privacy Policy or our
                use of your Personal Data, please contact us at:
            </Paragraph>
            <Paragraph>
                Jawbone Health Hub, Inc.
                <br />
                Attn: Legal Department (Privacy Policy)
                <br />
                200 Kansas St, #205
                <br />
                San Francisco, California 94103
                <br />
                U.S.A.
            </Paragraph>
            <Paragraph>
                Email:{' '}
                <LegalEmailLink href="mailto:&#108;&#101;&#103;&#097;&#108;&#064;&#106;&#097;&#119;&#098;&#111;&#110;&#101;&#104;&#101;&#097;&#108;&#116;&#104;&#046;&#099;&#111;&#109;">
                    &#108;&#101;&#103;&#097;&#108;&#064;&#106;&#097;&#119;&#098;&#111;&#110;&#101;&#104;&#101;&#097;&#108;&#116;&#104;&#046;&#099;&#111;&#109;
                </LegalEmailLink>
            </Paragraph>
        </LegalWrap>
    </DefaultLayout>
)
